/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Typography, Button, Theme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';

import Container from '@mui/material/Container';

import useMediaQuery from '@mui/material/useMediaQuery';

function NotFoundView() {
  const navigate = useNavigate();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        py: '80px'
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 6
          }}
        >
          <Box
            alt="Not found"
            component="img"
            src="/images/error-404.png"
            sx={{
              height: 'auto',
              maxWidth: '100%',
              width: 400
            }}
          />
        </Box>
        <Typography align="center" variant={mdUp ? 'h1' : 'h4'}>
          404: Oops! We can't find that page.
        </Typography>
        <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
          It looks like the page you're searching for has moved, been deleted,
          or just doesn't exist. But don't worry! You can click the button below
          to go back to our homepage or use the menu to navigate to other
          sections of the site. Let's get you back on track.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6
          }}
        >
          <Button
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            variant="contained"
            size="large"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back to Home
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default NotFoundView;
