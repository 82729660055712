import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FieldError } from 'react-hook-form';
import React from 'react';

interface ProblemStatementAutocompleteProps<T> {
  options: T[];
  error?: FieldError;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string | null) => void;
  // eslint-disable-next-line no-unused-vars
  getOptionLabel: (option: T) => string;
  label: string;
}

export function ProblemStateAutocomplete<T>({
  options,
  getOptionLabel,
  onChange,
  label,
  error
}: ProblemStatementAutocompleteProps<T>) {
  return (
    <Autocomplete
      disablePortal
      size="small"
      fullWidth
      onChange={(event, value) => {
        if (value) {
          // @ts-ignore
          onChange(value);
        } else {
          onChange(null);
        }
      }}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          error={error && Boolean(error.message)}
          name="branch"
          margin="normal"
          helperText={error?.message}
          {...params}
          label={label}
        />
      )}
    />
  );
}

export default ProblemStateAutocomplete;
