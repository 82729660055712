import { Container, Button, Link } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';

const HeadingTitle = styled('h1')(() => ({
  color: '#c22328',
  fontSize: 'clamp(1rem, 7vw, 4rem)',
  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
  marginTop: '75px'
}));

function Header() {
  return (
    <div className="Header">
      <Container fixed>
        <div className="text-bot">
          <HeadingTitle>
            Do you have
            <br /> a creative
            <br /> solution?
          </HeadingTitle>
          <br />
          <div>
            <div>
              <Link component={RouterLink} to="/signup" variant="h6">
                <Button size="large" variant="contained">
                  Apply Now
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Header;
