import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FieldError } from 'react-hook-form';

export const zanacoBranches = [
  'MOF Agency',
  'Head Office',
  'Woodlands Agency',
  'Northmead Branch',
  'Lusaka Civic Centre',
  'Woodlands Branch',
  'Siavonga Branch',
  'Longacres Branch',
  'Chirundu Branch',
  'UNZA Agency',
  'Manda Hill Agency',
  'Easy Banking Agency',
  'LSK INT Airport',
  'Manda Hill Branch',
  'Lusaka Business Centre',
  'Findeco House',
  'Twin Palms Branch',
  'Acacia Park Branch',
  'Water Falls Branch',
  'CBU Agency',
  'Masala Agency',
  'SMKIA Agency',
  'Kitwe Business Centre',
  'Ndola Business Centre',
  'Solwezi Branch',
  'Chingola Branch',
  'Ndola West Branch',
  'Luanshya Branch',
  'Kitwe Industrial',
  'Mukuba Branch',
  'Mufulira Branch',
  'Kazungula Agency',
  'Choma Branch',
  'Livingstone Branch',
  'Mazabuka Branch',
  'Monze Branch',
  'Maamba Branch',
  'Namwala Branch',
  'Mongu Branch',
  'Kabwe Business Centre',
  'Senanga Branch',
  'Kapiri Mposhi Branch',
  'Mkushi Branch',
  'Chisamba Branch',
  'Itezhi-Tezhi Branch',
  'Chipata Branch',
  'Petauke Branch',
  'Lundazi Branch',
  'Mfuwe Branch',
  'Nakonde Agency',
  'Kasama Branch',
  'Mansa Branch',
  'Kawambwa Branch',
  'Mpika Branch',
  'Nakonde Branch',
  'Chinsali Branch',
  'Bill Muster Centre',
  'Cairo Road Business Centre',
  'Lusaka Centre',
  'Premium House Branch',
  'Kafue Branch',
  'City Market Branch',
  'Government Complex Branch'
];

export const headOfficeDivisions = [
  'Client Solutions',
  'Internal Audit',
  'Strategy',
  'Treasury',
  'Compliance & Financial Crimes',
  'Marketing And Corporate Communications',
  'Legal Division',
  'Digital Banking',
  'Credit Risk',
  'Risk Operations',
  'Finance',
  'Human Capital',
  'Commercial',
  'Operations',
  'Information & Technology'
];

interface BranchAutocompleteProps<T> {
  options: T[];
  error?: FieldError;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string | null) => void;
  // eslint-disable-next-line no-unused-vars
  getOptionLabel: (option: T) => string;
  label: string;
}

export function BranchAutocomplete<T>({
  options,
  getOptionLabel,
  onChange,
  label,
  error
}: BranchAutocompleteProps<T>) {
  return (
    <Autocomplete
      disablePortal
      size="small"
      fullWidth
      onChange={(event, value) => {
        // @ts-ignore
        onChange(value);
      }}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          error={error && Boolean(error.message)}
          name="branch"
          margin="normal"
          helperText={error?.message}
          {...params}
          label={label}
        />
      )}
    />
  );
}

export function HeadOfficeDivisionAutocomplete<T>({
  options,
  getOptionLabel,
  onChange,
  label,
  error
}: BranchAutocompleteProps<T>) {
  return (
    <Autocomplete
      disablePortal
      size="small"
      fullWidth
      onChange={(event, value) => {
        // @ts-ignore
        onChange(value);
      }}
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          error={error && Boolean(error.message)}
          name="branch"
          margin="normal"
          helperText={error?.message}
          {...params}
          label={label}
        />
      )}
    />
  );
}
