import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';
import Header from '../hompage/Components/Header';
import AppBar from '../hompage/Components/AppBar';

import Footer from '../hompage/Components/Footer';
// import ResponsiveFAQAppBar from './faqAppbar';

export default function FAQ() {
  const { pathname } = useLocation();
  return (
    <div>
      {/* <ResponsiveFAQAppBar /> */}
      <AppBar />
      {pathname === '/faq' ? null : <Header />}

      <div className="layoutpadding" style={{ backgroundColor: '#fff' }}>
        <Typography
          variant="h1"
          sx={{
            marginTop: '100px',
            color: '#c22328',
            textAlign: 'center',
            paddingBottom: '20px'
          }}
        >
          Frequently Asked Questions (FAQs)
        </Typography>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="faq">
              Where can I access the application form?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>The application form can be accessed here</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="faq">
              How long is the ‘Sprint’ period?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The Sprint period will run for 12 working days.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="faq">Who is eligible to apply?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The challenge is open to all members of Zanaco Bank across the
              country
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="faq">
              When is the application deadline?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The application open 12th April - Friday 3rd May 2024
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="faq">
              What are the benefits of applying for the Zanaco Innovation
              Challenge?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <li style={{ marginBottom: 1 }}>
                A week of learning experience in building innovative solutions
                by Innovation experts.
              </li>
            </Typography>
            <Typography>
              <li style={{ marginBottom: 1 }}>
                An opportunity to have your innovative ideas adopted by the bank
                on a national level.
              </li>
            </Typography>
            <Typography>
              <li style={{ marginBottom: 1 }}>
                An opportunity to have the 3 winning ideas developed into
                working prototypes.
              </li>
            </Typography>
            <Typography>
              <li style={{ marginBottom: 1 }}>
                Contribute to the development of improved and excellent customer
                experience and service delivery across all platforms.
              </li>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="faq">
              When does the Challenge begin?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The call for applications begins on the 2nd of April 2024.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="faq">
              What specialised skills will I learn during the programme?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Typography variant="body1">
                <li style={{ marginBottom: 1 }}>Persona identification</li>
              </Typography>

              <Typography variant="body1">
                <li style={{ marginBottom: 1 }}>Refining personas</li>
              </Typography>

              <Typography variant="body1">
                <li style={{ marginBottom: 1 }}>Understanding user problems</li>
              </Typography>

              <Typography variant="body1">
                <li style={{ marginBottom: 1 }}>
                  Problem statement development
                </li>
              </Typography>

              <Typography variant="body1">
                <li style={{ marginBottom: 1 }}>Solution framing </li>
              </Typography>

              <Typography variant="body1">
                <li style={{ marginBottom: 1 }}>Creating User journey maps</li>
              </Typography>

              <Typography variant="body1">
                <li style={{ marginBottom: 1 }}>The art of Pitching</li>
              </Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="faq">
              What coaching sessions are available?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <li style={{ marginBottom: 1 }}>
                Coaching sessions are one to one meetings with inhouse and
                external coaches designed to address unique idea needs. Tailored
                sessions include;
              </li>
            </Typography>

            <Typography>
              <li style={{ marginBottom: 1 }}>
                Business Objective and Context
              </li>
            </Typography>

            <Typography>
              <li style={{ marginBottom: 1 }}>
                Introduction to Lean Model Canvas (with a focus on the Customer
                Problem)
              </li>
            </Typography>

            <Typography>
              <li style={{ marginBottom: 1 }}>
                Introduction to Interview Scripting.
              </li>
            </Typography>

            <Typography>
              <li style={{ marginBottom: 1 }}>Customer Persona</li>
            </Typography>

            <Typography>
              <li style={{ marginBottom: 1 }}>Customer Journeys</li>
            </Typography>

            <Typography>
              <li style={{ marginBottom: 1 }}>Unique Value Proposition</li>
            </Typography>

            <Typography>
              <li style={{ marginBottom: 1 }}>Revenue Models</li>
            </Typography>
            <Typography>
              <li style={{ marginBottom: 1 }}>An Introduction to Pitching</li>
            </Typography>
            <Typography>
              <li style={{ marginBottom: 1 }}>
                Various thematic coaching sessions on Sprint Week modules
              </li>
            </Typography>
          </AccordionDetails>
        </Accordion> */}
      </div>
      <Footer />
    </div>
  );
}
