import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Container, Typography, Box } from '@mui/material';

export default function ChallengeStatementAccordion() {
  return (
    <Container sx={{ paddingBottom: 8 }}>
      <Box
        sx={{
          textAlign: 'center',
          padding: 5
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            color: '#c22328',
            fontWeight: 'bold',
            fontSize: '2.5rem'
          }}
        >
          Our Challenge Statements
        </Typography>
      </Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>1. Enhancing Self-Service Options:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Whether its account opening, updating KYC, limit enhancement or
            resetting pins, enhancing self- service options can greatly improve
            customer satisfaction and streamline operation.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography> 2. Transactional Requests:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Mulenga expects seamless transactional request handling whenever he
            interacts with the bank. <br />
            <b>
              How can we raise customer satisfaction levels by streamlining the
              transactional requests from start to finish?
            </b>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography>3. Cash Out end –to-end Process:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            One key pain point for Zanaco customers using the cash out process
            to is non-delivery of the OTP and delayed PINs.
            <br />
            <b>
              How can we enhance the cash out process to prevent reversals,
              delayed PINs, and OTP non-delivery?
            </b>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography>4. Account Opening:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            For banks looking to meet customer demands and differentiate
            themselves on the market, enabling account opening outside the
            branch can be a strategic move.
            <br />
            <b>
              How can Zanaco effectively enable on premise account opening to
              meet customer expectations while ensuring security and compliance?
            </b>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography>5. Loan Requests:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Bupe, like a lot of working professionals, uses loans to fill in his
            financial shortfalls. Bupe has stated that he needs to be up to date
            with his refinancing and receive a loan as quickly as possible, all
            without going to a branch or making a call to the call center.
            <br />
            <b>
              How can we optimize the Retail & Micro Loan process to ensure an
              efficient end-to-end customer experience?
            </b>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}
