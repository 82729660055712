/* eslint-disable react/function-component-definition */
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  Alert,
  AlertTitle
} from '@mui/material';

import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { axios } from '../../../../clientProvider';
import Loading from '../../../../components/Loading';
import { RootState } from '../../../../redux/reducers/rootReducer';
import { ChallengeStatement } from '../../../../types';
import ChallengeStateAutocomplete from './ChallengeAutoComplete';
import ProblemStateAutocomplete from './ProblemAutoComplete';
import ClosedApplicationCard from '../../teams/components/ClosedApplicationCard';

export type ProposalFormInputs = {
  title: string;
  category: string;
  problem: string;
  proposedSolution: string;
  teamId: string | undefined;
};
interface Team {
  _id: string;
  name: string;
}
export const getTeam = async (id: string | undefined): Promise<Team[]> => {
  const { data } = await axios.get(`/Team/view_team_by_user/${id}`);
  return data.data;
};
interface Theme {
  _id: string;
  name: string;
}
const getThemes = async (): Promise<Theme[]> => {
  const { data: res } = await axios.get('/Theme/view_themes');
  return res.Themes;
};

const ProposalForm = () => {
  const [closed] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [challengeStatements, setChallengeStatements] = React.useState<
    ChallengeStatement[]
  >([]);

  const { user } = useSelector((state: RootState) => state.user);

  const { data: teamData, isLoading: isLoadingTeam } = useQuery(
    ['team', user?._id],
    () => getTeam(user?._id)
  );
  const { data: themeData } = useQuery(['Theme'], () => getThemes());

  const {
    register,
    handleSubmit,
    control,
    watch,

    formState: { errors }
  } = useForm<ProposalFormInputs>({
    mode: 'onChange'
  });
  const themeId = watch('problem');
  const category = watch('category');

  React.useEffect(() => {
    const fetchChallengeStatements = async () => {
      try {
        const response = await axios.get(
          // @ts-ignore
          `/Challenge/view_challenge_by_theme/${themeId._id}`
        );
        setChallengeStatements(response?.data?.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (themeId) {
      fetchChallengeStatements();
    }
  }, [themeId, category]);

  const { mutate, isLoading } = useMutation(
    async (data: ProposalFormInputs) =>
      axios.post('/Innovation/new_innovation', data),
    {
      onSuccess: (response) => {
        const { message } = response.data;
        enqueueSnackbar(message, { variant: 'success' });
        setTimeout(() => navigate('/team/innovation-idea'), 1500);
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(error.response?.data, { variant: 'error' });
      },

      onSettled: () => {
        queryClient.invalidateQueries(['AdminUser']);
        queryClient.invalidateQueries(['Team-proposal']);
        queryClient.invalidateQueries(['submissions']);
      }
    }
  );

  const onSubmit = (data: ProposalFormInputs) => {
    const formData = {
      ...data,
      // @ts-ignore
      problem: data.problem.name,
      leadId: user?._id,
      // teamId,
      challengeStatementId: data.category
    };
    mutate(formData);
  };

  if (isLoadingTeam) return <Loading size={45} />;
  if (closed) return <ClosedApplicationCard />;
  if (!closed && themeData)
    return (
      <div
        style={{
          backgroundColor: '#fff',
          flexGrow: 1,
          padding: '20px',
          marginLeft: '15px',
          marginRight: '15px',
          marginTop: '20px'
        }}
      >
        <Typography
          variant="h1"
          fontWeight="bold"
          sx={{ color: '#00A1E0', textTransform: 'capitalize' }}
        >
          idea submission
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h4" color="primary" sx={{ paddingTop: '4%' }}>
            1. What’s the title of your innovation?
          </Typography>
          <TextField
            error={Boolean(errors.title)}
            variant="outlined"
            fullWidth
            {...register('title', { required: true })}
            margin="normal"
            sx={{ paddingBottom: '4%' }}
            size="small"
            type="text"
          />
          <Typography variant="h4" color="primary">
            2. Select a theme you are solving for?
          </Typography>
          <Controller
            rules={{ required: 'Theme is required' }}
            name="problem"
            control={control}
            render={({ field: { onChange } }) => (
              <ProblemStateAutocomplete
                options={themeData}
                onChange={onChange}
                getOptionLabel={(option) => option.name}
                label="Problem"
              />
            )}
          />

          <Typography variant="h4" color="primary">
            3. What Challenge Statement Does Your solution address?
          </Typography>
          {(challengeStatements?.length > 0 && themeId !== null) ||
          category == null ? (
            <>
              <Controller
                rules={{ required: 'Challenge statement is required' }}
                name="category"
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => (
                  <ChallengeStateAutocomplete
                    options={challengeStatements}
                    onChange={onChange}
                    error={error}
                    getOptionLabel={(option) => option.challengeStatement}
                    label="Challenge Statements"
                  />
                )}
              />

              {category && category !== null ? (
                <Alert
                  severity="info"
                  sx={{ marginBottom: 4 }}
                  title="Your selection"
                >
                  <AlertTitle>Full Challenge Statement</AlertTitle>
                  {challengeStatements
                    .filter((challenge) => challenge._id === category)
                    .map((t) => t.challengeStatement)}
                </Alert>
              ) : null}
            </>
          ) : (
            <Typography
              variant="body1"
              color="error"
              sx={{
                padding: 2,
                backgroundColor: '#f2f2f1',
                marginBottom: 2,
                marginTop: 2
              }}
            >
              Please select a theme in question 2
            </Typography>
          )}
          <Typography variant="h4" color="primary">
            4. What is the proposed solution?
          </Typography>
          <TextField
            multiline
            rows={4}
            error={Boolean(errors.proposedSolution)}
            variant="outlined"
            fullWidth
            {...register('proposedSolution', { required: true })}
            margin="normal"
            sx={{ paddingBottom: '4%' }}
            size="small"
            type="text"
          />
          <Typography variant="h4" color="primary">
            5. Select team your submitting for?
          </Typography>

          {teamData ? (
            <Controller
              rules={{ required: 'Team is required' }}
              name="teamId"
              control={control}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <ChallengeStateAutocomplete
                  options={teamData}
                  error={error}
                  onChange={onChange}
                  getOptionLabel={(option) => option.name}
                  label="Teams"
                />
              )}
            />
          ) : null}

          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ margin: 1 }}
            size="large"
            startIcon={
              isLoading ? <CircularProgress color="inherit" size={26} /> : null
            }
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => navigate('/team/innovation-idea')}
          >
            Cancel
          </Button>
        </form>
      </div>
    );
  return null;
};

export default ProposalForm;
