import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  CardActionArea,
  CardActions,
  Button
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: '#c22328'
    },
    secondary: {
      main: '#eecf9f'
    }
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    h5: {
      fontWeight: 600
    },
    body2: {
      fontSize: '1rem'
    }
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Soft box shadow
          borderRadius: '8px',
          margin: '20px'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px' // Inner spacing in the card content
        }
      }
    }
  }
});

function ClosedApplicationCard() {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Card sx={{ maxWidth: 800 }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Applications Closed
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Thank you for your interest in the Zanaco Innovation Challenge. We
              are currently not accepting new applications. Please check back
              later for updates.
            </Typography>
          </CardContent>
          <CardActionArea>
            <CardActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => navigate(-1)}
              >
                Go Back
              </Button>
            </CardActions>
          </CardActionArea>
        </Card>
      </Box>
    </ThemeProvider>
  );
}

export default ClosedApplicationCard;
