/* eslint-disable react/function-component-definition */
import * as React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import {
  ThemeProvider,
  StyledEngineProvider,
  Theme
} from '@mui/material/styles';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  onlineManager
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useSnackbar } from 'notistack';
import * as Sentry from '@sentry/react';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import Notifier from './Notifier';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  // eslint-disable-next-line no-unused-vars
  interface DefaultTheme extends Theme {}
}

Sentry.init({
  dsn: 'https://2e8d6f92898df600f9155f8d666ebc34@o4507096810127360.ingest.us.sentry.io/4507098512097280',
  beforeSend(event, hint) {
    console.log(hint);
    console.log(event);
    /**
     * Check if it is an exception, and if so, show the report dialog
     */
    if (event.exception && event.event_id) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      /**
       * Additional SDK configuration goes in here
       */
      colorScheme: 'system',
      isNameRequired: true,
      isEmailRequired: true
    })
  ],
  /**
   * Performance Monitoring
   */
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  /**
   * Set 'tracePropagationTargets' to control for which URLs distributed tracing
   * should be enabled
   */
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/zanaco.demo.co.zm\/api/,
    /^https:\/\/zanaco.innovation.co.zm\/api/
  ],

  /**
   *  Session Replay
   */
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const routing = useRoutes(routes);
  const { enqueueSnackbar } = useSnackbar();
  const isOnline = onlineManager.isOnline();
  // const dispatch = useDispatch();
  // const cookieValue = Cookies.get('Zanaco');
  // const deserializedValue = JSON.parse(cookieValue || '{}');

  // React.useEffect(() => {
  //   if (Object.keys(deserializedValue).length === 0) {
  //     console.log('Cookie does not exist');
  //   } else {
  //     dispatch(setCookie(deserializedValue));
  //   }
  // }, [deserializedValue]);

  const [queryClient] = React.useState<QueryClient>(
    new QueryClient({
      /**
       * Network mode (https://react-query-alpha.tanstack.com/guides/network-mode)
       */
      defaultOptions: {
        queries: {
          retry: 1,
          networkMode: 'offlineFirst'
        },
        mutations: {
          networkMode: 'offlineFirst'
        }
      },

      queryCache: new QueryCache({
        onError: (error, query) => {
          /**
           * Only show error toasts if we already have data in the cache
           * which indicates a failed background update
           */
          // @ts-ignore
          if (!query?.state?.error?.response?.status === 404) {
            if (query.state.data === undefined) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              enqueueSnackbar(`Something went wrong: ${error?.message}`, {
                variant: 'error'
              });
            }
          }
          // } else if (query.state.data === undefined) {
          //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //   // @ts-ignore
          //   enqueueSnackbar(`Something went wrong: ${error?.message}`, {
          //     variant: 'error'
          //   });
          // }
        }
      })
    })
  );

  /**
   * Check if the user is online and if not, show a toast
   */
  if (!isOnline) {
    enqueueSnackbar(
      'You are offline. Please connect to the internet to use this app.',
      {
        variant: 'error',

        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 5000
      }
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Notifier />
          <GlobalStyles />
          {routing}
        </ThemeProvider>
      </StyledEngineProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
