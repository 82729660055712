import React from 'react';
import { Typography, Grid, Container, Box } from '@mui/material';
// import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
// import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

export default function Section() {
  return (
    <div className="topPadding">
      {/* <Typography
        sx={{
          textAlign: 'center',
          color: '#0133a1',
          fontWeight: 'bold',
          fontSize: '2.5rem',
          //   paddingTop: '5px',
          marginBottom: '25px'
        }}
      >
        Zanaco Innovation 2.0
      </Typography> */}
      <Container fixed id="About">
        <Grid container className="color" columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid
            item
            xs={6}
            className="section2"
            spacing={2}
            style={{
              backgroundColor: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              textAlign: 'left'
            }}
          >
            <Typography variant="body1" style={{ wordSpacing: 'normal' }}>
              <Box sx={{ mb: 1 }}>
                As Zanaco continues to be Zambia’s leading, admired and
                preferred financial institution; cultivating and nurturing
                Innovation remains a top priority to provide the best value
                solutions for our clientele.
              </Box>
            </Typography>
            <Typography variant="body1" style={{ wordSpacing: 'normal' }}>
              <Box sx={{ mb: 1 }}>
                In our quest to be the top-of-mind universal transaction for all
                segments, we are committed to delivering excellent financial
                services efficiently, supported by an empowered and motivated
                staff.
              </Box>
            </Typography>
            {/* <br /> */}
            <Typography variant="body1" style={{ wordSpacing: 'normal' }}>
              <Box sx={{ mb: 1 }}>
                Introducing the Zanaco Innovation Challenge! .An opportunity for
                every member of staff at Zanaco to contribute creative and
                innovative ideas aimed at enhancing excellent service delivery.
              </Box>
            </Typography>
            <Typography variant="body1" style={{ wordSpacing: 'normal' }}>
              Whether you have a revolutionary product idea, a process
              improvement suggestion, or a game-changing strategy, this portal
              is the perfect space to unleash your creativity. Your innovative
              ideas can transform our clients’ experience, allow us to execute
              with excellence, and sustainably drive value and growth.
            </Typography>
            <br />
            <Typography
              style={{ fontSize: '16px', textAlign: 'left' }}
              color="primary"
            >
              Are you ready to jump right in? Let us show you how!
            </Typography>
            <br />
          </Grid>
          <Grid
            item
            xs={6}
            sm={2}
            md={6}
            lg={6}
            xl={6}
            className="woman"
            sx={{ display: { xs: 'none', sm: 'flex', md: 'flex' } }}
          />
        </Grid>
      </Container>
    </div>
  );
}
